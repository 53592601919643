<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="共有情報登録" />
    <AppSideMenu />

    <main class="l-main">
      <!-- 入力フォームエリアを入力値チェックエリアとして監視対象とする -->
      <validation-observer ref="validation">
        <div class="l-container">
          <!-- エラーメッセージ表示フィールド -->
          <ErrorMessage :errMsgs="errMsgs" />
          <div class="c-form__reqTxt l-item">：入力必須</div>
          <div class="l-contents l-box">
            <!--基本情報-->
            <div class="l-box">
              <h3 class="c-lead icon">基本情報</h3>
            </div>

            <!-- 件名フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>件名</label>
              </div>
              <div class="c-inputWrap__items">
                <!-- 必須項目&100文字制限のルール追加 -->
                <InputText
                  :value.sync="form.title"
                  inputType="text"
                  placeholder="件名を入力してください"
                  tooltip="100文字以内で入力してください"
                  labelName ="件名"
                  rules = "required|max_wordlength:100"
                />
              </div>
            </div>
            <!-- 件名フィールド END -->

            <!-- 内容フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>内容</label>
              </div>
              <div class="c-inputWrap__items">
                <!-- 必須項目のルール追加 -->
                <MyTextarea
                  :value.sync="form.content"
                  rows="6"
                  placeholder="入力してください"
                  labelName ="内容"
                  rules = "required"
                ></MyTextarea>
              </div>
            </div>
            <!-- 内容フィールド END -->

            <!-- 運搬事業者フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  :value.sync="form.collectCompanyId"
                >
                  <option value=""></option>
                  <option
                    v-for="item in generatorCollectList"
                    :key="'jwnet-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <!-- 運搬事業者フィールド END -->

            <!-- 処分事業者フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  :value.sync="form.disposalCompanyId"
                >
                  <option value=""></option>
                  <option
                    v-for="item in generatorDisposalList"
                    :key="'jwnet-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <!-- 処分事業者フィールド END -->

            <!-- 担当者フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <!-- 必須項目のルール追加 -->
                <Selectbox
                  :value.sync="form.memberId"
                  labelName ="担当者"
                  rules = "required"
                >
                  <option
                    v-for="item in inputMemberList"
                    :key="'jwnet-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <!-- 担当者フィールド END -->

            <!-- 社内サーバーリンクパスフィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>社内サーバーリンクパス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.serverLink"
                  inputType="text"
                  placeholder="パスを入力してください"
                />
              </div>
            </div>
            <!-- 社内サーバーリンクパスフィールド END -->

            <!-- ファイル選択フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>添付ファイル</label>
              </div>
              <div class="c-inputWrap__items">
                <!-- 容量制限エラーメッセージ表示領域 -->
                <span style="color: #ff0000">{{ sizeErrorMsg }}</span>
                <!-- 5件までアップロード可能 -->
                <template v-if="form.shareInfoFileList.length !== 5">
                  <input
                    id="file-upload"
                    type="file"
                    @change="(e) => uploadFile(e)"
                  />
                  <label
                    class="c-btn secondary upload fileupload-sm"
                    for="file-upload"
                  >
                    ファイルアップロード
                  </label>
                </template>
                <!-- ファイル添付数が5件到達した場合、ボタン非活性 -->
                <template v-else>
                  <label
                    class="c-btn secondary upload fileupload-sm disabled"
                    for="file-upload"
                  >
                    ファイルアップロード
                  </label>
                </template>
                <template v-if="form.shareInfoFileList.length">
                  <div
                      v-for="(file, index) in form.shareInfoFileList" 
                      :key="index"
                      class="l-flex start"
                  >
                    <div class="l-area upload-filename">
                      {{file.fileName}}
                    </div>
                    <label
                      class="c-btn secondary delete sm align-center"
                      @click="deleteFileList(index)"
                    >
                      削除
                    </label>
                  </div>
                </template>
              </div>
            </div>
            <!-- ファイル選択フィールド END -->

            <!-- 次回更新日フィールド START -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>次回更新日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputDate
                    :value.sync="form.nextUpdateDate"
                    placeholder="0000/00/00"
                    :disabledDates="{to: new Date(new Date().toDateString()) }"

                  />
                </div>
              </div>
            </div>
            <!-- 次回更新日フィールド END -->
          </div>
        </div>
        <div class="l-btns l-container">
          <div class="center">
              <div class="c-btn primary mid" @click="formHandler()">登録</div>
          </div>
        </div>
      </validation-observer>

      <!-- 次へ確認モーダル -->
      <div class="c-modal" v-show="isConfirmModalShow">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            共有情報を登録します。<br />
            よろしいですか？
          </div>
          <ModalFooterButtons :processing="processing">
            <div
              class="c-btn secondary small"
              @click="closeConfirmModalHandler"
            >
              戻る
            </div>
            <div class="c-btn primary small" @click="registerHandler">はい</div>
          </ModalFooterButtons>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/veevalidation/InputTextV2";
import InputDate from "@/components/parts/input/InputDate";
import MyTextarea from "@/components/parts/input/veevalidation/MyTextareaV2";
import Selectbox from "@/components/parts/input/veevalidation/SelectboxV2";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import validation from "@/mixin/validation";
import formValidation from "../mixins/formValidation.js";
import callApi from "../mixins/callApi";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  name: "shareinfo-input",
  components: {
    InputText,
    InputDate,
    MyTextarea,
    Selectbox,
    ModalFooterButtons,
  },
  mixins: [validation, formValidation, callApi, selectionLocalStorage],
  data() {
    return {
      errMsgs: [],
      // ファイルアップロード用配列
      maxUploadFile: 5,
      // 登録確認モーダル表示フラグ
      isConfirmModalShow: false,
      processing: false,
      sizeErrorMsg: "",
    };
  },
  methods: {
    // ファイルをアップロード
    uploadFile(e) {
      if (!e.target.files[0]) return;
      const sizeLimit = 1024 * 1024 * 30; // 制限サイズ(30MB)
      const file = e.target.files[0] || e.dataTransfer.files[0];
      if (file.size >= sizeLimit) {
        this.sizeErrorMsg = "ファイルサイズは30MB以下にしてください";
        return;
      }
      this.uploadApi(file)
        .then((path) => {
          this.form.shareInfoFileList.push({
            filePath: path,
            fileName: file.name,
          });
        })
        .finally(() => {
          this.sizeErrorMsg = "";
        });
    },
    // 追加したファイル選択項目を削除する
    deleteFileList(index) {
      this.form.shareInfoFileList.splice(index, 1);
      this.sizeErrorMsg = "";
    },
    // 登録ボタン発火
    formHandler() {
      this.errMsgs = [];
      // 入力チェックを実施
      if (this.$refs.validation.flags.invalid) {
        this.errMsgs.push("入力に誤りがあります。");
        // 入力チェック対象で条件を満たしていないフィールドにエラー文言を表示させる
        this.$refs.validation.validate();
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.isConfirmModalShow = true;
    },
    // 登録確認モーダルの「戻る」押下時
    closeConfirmModalHandler() {
      this.isConfirmModalShow = false;
    },
    // 登録確認モーダルの「OK」押下時
    registerHandler() {
      this.processing = true;
      const postData = JSON.parse(JSON.stringify(this.form));
      // 共有情報登録APIの実行
      this.inputShareInfoApi(postData);
    },
  },
  created() {
    // 担当者情報取得API
    this.getShareInfoInputMemberListApi();
    // 処分事業者情報取得API
    this.getGeneratorDisposalApi();
    // 運搬事業者情報取得API
    this.getGeneratorCollectApi();
  },
};
</script>
